import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Board } from '../../components/Board';
import { DebugView } from './components/DebugView';
import { ExtraLives } from './components/ExtraLives';
import { GameOver } from './components/GameOver';
import { GhostsGameView } from './components/GhostsView';
import { MazeView } from './components/MazeView';
import { PacManView } from './components/PacManView';
import { PillsView } from './components/PillsView';
import { Score } from './components/Score';
import { useStore } from '../../components/StoreContext';
import { useKeyboardActions } from './components/useKeyboardActions';
import { HSpace, VSpace } from '../../components/Spacer';
import { useGameLoop } from '../../model/useGameLoop';
import { isMobile } from 'react-device-detect';


export const GamePage: React.FC = observer(() => {
  const store = useStore();
  useEffect(() => {
    store.resetGame();
    return () => {
      store.game.gamePaused = true;
    };
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useGameLoop();
  useKeyboardActions();

  const move = (direction: string) => {
    const { game } = store;
    const pacMan = game.pacMan;
    switch (direction) {
      case 'LEFT':
        pacMan.nextDirection = 'LEFT';
        break;
      case 'RIGHT':
        pacMan.nextDirection = 'RIGHT';
        break;
      case 'UP':
        pacMan.nextDirection = 'UP';
        break;
      case 'DOWN':
        pacMan.nextDirection = 'DOWN';
        break;
      case ' ':
        game.gamePaused = !game.gamePaused;
        break;
      default:
        break;
    }
  };

  return (
    <Layout data-testid="GamePage">
      <Row justify="space-between">
        <ScoreArea>
          <Row>
            <Score />
          </Row>
          <VSpace size="small" />
        </ScoreArea>
        <HSpace size='large' />
        <Row justify='end'>
          <ExtraLives />
        </Row>
      </Row>
      <EmptyArea />

      <BoardArea>
        <Board>
          <MazeView />
          <PillsView />
          <PacManView />
          <GhostsGameView />
          <GameOver />
        </Board>
        <VSpace size="large" />
        { isMobile ? <GamePad>
          <span></span>
          <DirectionButton onClick={() => move('UP')}>
            <span className="material-icons">arrow_upward</span>
          </DirectionButton>
          <span></span>
          <DirectionButton onClick={() => move('LEFT')}>
            <span className="material-icons">arrow_back</span>
          </DirectionButton>
          <span></span>
          <DirectionButton onClick={() => move('RIGHT')}>
            <span className="material-icons">arrow_forward</span>
          </DirectionButton>
          <span></span>
          {/* <span></span> */}
          <DirectionButton onClick={() => move('DOWN')}>
            <span className="material-icons">arrow_downward</span>
          </DirectionButton>
        </GamePad> : null}
      </BoardArea>

      {/* <DebugArea>
        <DebugView />
      </DebugArea> */}
    </Layout>
  );
});

const Layout = styled.div`
  margin-left: 16px;
  margin-right: 16px;

  display: grid;

  @media (min-width: 1280px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`;

const ScoreArea = styled.div``;

const EmptyArea = styled.div``;

const BoardArea = styled.div``;

const DebugArea = styled.div`
  @media (max-width: 1280px) {
    display: none;
  }
`;

const DirectionButton = styled.button`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #000;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
`;

const GamePad = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 240px;
  margin: auto;
`;
